import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  
  { path: 'home',
  children: [
    {
     path : '',
     loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    },

    {
     path : ':productId',
     loadChildren: () => import('./pages/productdetails/productdetails.module').then( m => m.ProductdetailsPageModule)
    }
    ]
   },
   {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'categories',
    children: [
      {
      path : '',
      loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule)
      },

      {
        path : ':catId',
        loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
      }
    ]
  },

  {
    path: 'tags',
    children: [
      {
      path : '',
      loadChildren: () => import('./pages/tags/tags.module').then( m => m.TagsPageModule)
      },

      {
        path : ':tagId',
        loadChildren: () => import('./pages/tag/tag.module').then( m => m.TagPageModule)
      }
    ]
  },

  { path: 'orders',
  children: [
    {
     path : '',
     loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule),
    canActivate: [AuthGuard]
    },

    {
     path : ':orderId',
     loadChildren: () => import('./pages/orderdetails/orderdetails.module').then( m => m.OrderdetailsPageModule),
     canActivate: [AuthGuard]
    }
    ]
   },
   {
    path: 'customerprofile',
    loadChildren: () => import('./pages/customerprofile/customerprofile.module').then( m => m.CustomerprofilePageModule), canActivate: [AuthGuard]
  },
  {
    path: 'resetpassword',
    loadChildren: () => import('./pages/resetpassword/resetpassword.module').then( m => m.ResetpasswordPageModule)
  },
  {
    path: 'thankyou',
    loadChildren: () => import('./pages/thankyou/thankyou.module').then( m => m.ThankyouPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
