import { Injectable } from '@angular/core';
import { ToastController, AlertController } from "@ionic/angular";
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonfunctionService {

  private userDisplayName = new Subject<any>();

  constructor(private toastCtrl: ToastController, private alertCtrl: AlertController) { }

  async presentToast(messageToShow,mesgPosition,showDuration){
    const toast = await this.toastCtrl.create({
      message: messageToShow,
      position: mesgPosition,
      duration: showDuration
    });
    toast.present();
}

presentAlert(alrtHeader,alrtMessage) {
  let alert = this.alertCtrl.create({
    header: alrtHeader,
    subHeader: alrtMessage,
    buttons: ['Dismiss']
  }).then ((alert) =>{
   alert.present();
  });
 
}

validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

setDisplayName(displayName: string) {
  this.userDisplayName.next({ display_name: displayName });
}

getDisplayName(): Observable<any> {
  return this.userDisplayName.asObservable();
}

// for home page
homeFilterProduct() {
  // Declare variables
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("homeProductInput");
  filter = input.value.toUpperCase();
  //console.log('Typed Value: ',filter);
  table = document.getElementById("productsContainer");
  tr = table.getElementsByTagName("ion-col");
  //console.log('tr length: ', tr.length)

  // Loop through all table rows, and hide those who don't match the search query
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("ion-card-subtitle")[0];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}

}
