import { CommonfunctionService } from 'src/app/services/commonfunction.service';
import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Subscription  } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Categories',
      url: '/categories',
      icon: 'folder-open'
    },
    {
      title: 'Tags',
      url: '/tags',
      icon: 'receipt'
    },
    {
      title: 'My Orders',
      url: '/orders',
      icon: 'reorder-four'
    },
    {
      title: 'My Account',
      url: '/customerprofile',
      icon: 'person-circle'
    }
  ];

  currentDisplayName: any;
  private subsCription: Subscription;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private CFS: CommonfunctionService,
    private router: Router
  ) {
    this.initializeApp();
    this.subsCription = this.CFS.getDisplayName().subscribe((displayName) => {
      if(displayName){
        this.currentDisplayName = displayName;
        //console.log('Current Display Name: ',this.currentDisplayName);
      } else {
        this.currentDisplayName = '';
      }
    });

    if(localStorage.getItem('display_name')){
      this.currentDisplayName = {
        'display_name': localStorage.getItem('display_name')
      }
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  logOut(){
    localStorage.clear();
    this.router.navigateByUrl('/login');
    window.location.reload();
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
}
